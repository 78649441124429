import styled from "styled-components";

export const GridNav = styled.ul`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	font-size: 1.4em;
	font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1200 - 320)));
	justify-items: center;
	margin: 0 0 2em;
	padding: 0;
	pointer-events: all;
	text-align: end;

	@media (min-width: 650px) {
		display: block;
	}

	li {
		cursor: pointer;
		display: inline-block;
		margin: 0;
		padding: 0;
		position: relative;

		&.is-reset {
			grid-column: 1 / -1;
			order: 10;
			padding: 1em 0 0 0;
    		text-align: center;

			@media (min-width: 650px) {
				padding: 0;
			}

			&.is-hidden {
				inset-block-start: -200vh;
				opacity: 0;
				pointer-events: none;
				position: absolute;
			}

		}
	}

	/*@media screen and (min-width: 650px) {*/
		li {
			padding: 0;
			/*text-transform: uppercase;*/

				@media screen and (min-width: 650px) {
				+ li {
					margin: 0 0 0 30px;
					padding: 0 0 0 20px;
				}
			}
			span {
				/*
				border: 2px solid transparent;
				border-bottom-color: var(--color-grid-nav-filters-border);
				*/

				display: inline-block;
				padding: 4px;
				text-decoration: underline;
				text-decoration-style: solid;
				text-decoration-thickness: 2px;
				text-underline-offset: 3px;

				&:hover {
					/*border-bottom-color: var(--color-brand);*/
					text-decoration-color: var(--color-brand);
					text-decoration-style: wavy;
				}
			}

			&:hover,
			&.active {
				&::before {
					inline-size: 100%;
					z-index: -1;
				}

				span {
					border-block-end-color: var(--color-brand);
				}
			}

			&.is-open {
				&::before {
					inline-size: 100%;
					z-index: -1;
				}

				span {
					border-block-end-color: var(--color-brand);
					text-decoration-color: var(--color-brand);
					text-decoration-style: wavy;
				}
			}

			&.is-reset {

				span {
					border: 2px solid var(--color-grid-nav-filters-border);
				}

				&:hover {
					span {
						border-color: var(--color-brand);
					}
				}
			}
		}

	/*}*/
`;

export const GridSettings = styled.div`
	animation: fade-in 1s;
	border: 4px solid var(--color-brand);
	margin: 0 0 2em;
	padding: 2em 2em calc(2em - 10px);


	@keyframes fade-in {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

`;
